<div fxShow="true" fxHide.lt-md="true" *ngFor="let change of changes">
  <div class="container1">
    <h1>{{ change.date }}</h1>
  </div>
  <div class="container2">
    <p>{{ change.desc }}</p>
  </div>
</div>
<div fxShow="true" fxHide.gt-sm="true" style="display:block;" *ngFor="let change of changes">
  <h1>{{ change.date }}</h1>
  <p>{{ change.desc }}</p>
</div>
