import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import firebase from 'firebase/compat';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private afAuth: AngularFireAuth) {
    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        if (this.userIsAdmin(user)) {
          this.isAdmin.next(true);
        }
        else {
          console.log("But you are not an admin...")
          this.isAdmin.next(false);
          this.deleteLoggedInUser();
        }
        this.isLoggedIn.next(true);
      } else {
        this.isAdmin.next(false);
        this.isLoggedIn.next(false);
      }
    });
  }

  GoogleAuth() {
    return this.AuthLogin(new GoogleAuthProvider());
  }

  AuthLogin(provider: firebase.auth.AuthProvider) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        console.log('You have been successfully logged in!');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  logOut() {
    this.afAuth.signOut().then(() => {
      this.isLoggedIn.next(false);
      this.isAdmin.next(false);
    }).catch((error) => {
      console.log(error);
    });
  }

  deleteLoggedInUser() {
    const auth = getAuth();
    const user = auth.currentUser;
    this.logOut();
    user?.delete().then(() => {
      this.isLoggedIn.next(false);
      this.isAdmin.next(false);
    }, error => {
      console.log(error)
    })
  }

  userIsAdmin(user: firebase.User) {
    if (user.email && environment.allowedEmails.includes(user.email)) {
      return true;
    }
    return false;
  }
}
