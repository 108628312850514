import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faBomb } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'plant-spot',
  template: `
    <fa-icon
      class="plant"
      [ngStyle]="plantSpotStyle"
      [icon]="faBomb"
      (mouseover)="isHovering = true"
      (mouseout)="isHovering = false"
      (click)="onClick()"
    ></fa-icon>
    <fa-icon
      *ngIf="isHovering"
      class="plant-outline"
      [ngStyle]="plantSpotStyle"
      [icon]="faCircle"
      transform="grow-10 left-1 down-1"
    ></fa-icon>
  `
})
export class PlantSpotComponent implements OnInit {
  faBomb = faBomb;
  faCircle = faCircle;

  isHovering: boolean = false;
  plantSpotStyle: any;

  @Input() x: number = 0;
  @Input() y: number = 0;
  @Input() name: string = "";

  @Output() plantClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.plantSpotStyle = {
      "left": this.x/10 + "%",
      "top": this.y/10 + "%"
    }
  }

  onClick() {
    this.plantClicked.emit();
  }
}
