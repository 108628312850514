import { Component, OnInit } from '@angular/core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Line } from '../entities/Line';
import { MapData } from '../entities/MapData';
import { MapDataService } from '../services/map-data.service';
import { environment } from 'src/environments/environment';
import { SubscriptionOwner } from '../services/subscription-owner.service';
import { AuthService } from '../services/auth.service';
import { MollySpot } from '../entities/MollySpot';
import { PlantSpot } from '../entities/PlantSpot';
import { OptionsService } from '../services/options.service';
import { Agents } from '../entities/Agents';

@Component({
  selector: 'lineups',
  templateUrl: './lineups.component.html',
})
export class LineUpsComponent extends SubscriptionOwner implements OnInit {
  mapData: MapData = new MapData();
  environment = environment;
  shouldAutoscroll = true;
  selectedAgent = Agents.Brimstone;
  mollySpots: MollySpot[] = [];

  filteredLines: Line[] = [];

  selectedMollyIndex = -1;
  selectedPlantIndex = -1;
  selectedLines: Line[] = [];

  faCircle = faCircle;

  isAdmin = false;

  isCreatingNewPlantSpot = false;
  isEditingPlantSpot = false;
  plantSpotIndexForEdit = -1;
  isCreatingNewMollySpot = false;
  isEditingMollySpot = false;
  mollySpotIndexForEdit = -1;
  devX: number = 50;
  devY: number = 50;
  imageUrlForSpot: string = '';

  isCreatingNewLine = false;
  isEditingLine = false;
  lineIndexForEdit = -1;
  isSelectingMollySpot = false;
  selectedMollySpotIndexForLine = -1;
  isSelectingPlantSpot = false;
  selectedPlantSpotIndexForLine = -1;
  lineDescription = '';
  lineLandTime = 0;

  constructor(
    private mapDataService: MapDataService,
    private authService: AuthService,
    private options: OptionsService
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.mapDataService.selectedMapData.subscribe((mapData) => {
        this.filteredLines = [];
        this.mapData = mapData;
        if (this.selectedAgent == Agents.Brimstone) {
          this.mollySpots = mapData.mollySpots;
        } else if (this.selectedAgent == Agents.Viper) {
          this.mollySpots = mapData.snakeBiteSpots;
        }
        this.getAllLines();
      })
    );
    this.subscriptions.push(
      this.authService.isAdmin.subscribe((x) => {
        this.isAdmin = x;
      })
    );
    this.subscriptions.push(
      this.options.selectedAgent.subscribe((x) => {
        this.selectedAgent = x;
        if (this.selectedAgent == Agents.Brimstone) {
          this.mollySpots = this.mapData.mollySpots;
        } else if (this.selectedAgent == Agents.Viper) {
          this.mollySpots = this.mapData.snakeBiteSpots;
        }
        this.getAllLines();
      })
    );
  }

  getAllLines() {
    this.selectedMollyIndex = -1;
    this.selectedPlantIndex = -1;
    this.selectedLines = [];
    this.filteredLines = this.getLinesDependingOnSelectedAgent();
  }

  getLinesByPlantSpot(plantSpotId: number) {
    if (this.isSelectingPlantSpot) {
      this.selectedPlantSpotIndexForLine = plantSpotId;
      this.isSelectingPlantSpot = false;
      return;
    }
    if (this.isEditingPlantSpot) {
      this.selectPlantSpotForEdit(plantSpotId);
      return;
    }

    this.selectedMollyIndex = -1;
    this.selectedLines = [];

    if (plantSpotId == this.selectedPlantIndex) {
      this.getAllLines();
      return;
    }

    this.selectedPlantIndex = plantSpotId;
    this.filteredLines = this.getLinesDependingOnSelectedAgent().filter(
      (line) => line.plantSpotId == plantSpotId
    );
  }

  getLinesByMollySpot(mollySpotId: number) {
    if (this.isSelectingMollySpot) {
      this.selectedMollySpotIndexForLine = mollySpotId;
      this.isSelectingMollySpot = false;
      return;
    }
    if (this.isEditingMollySpot) {
      this.selectMollySpotForEdit(mollySpotId);
      return;
    }

    this.selectedPlantIndex = -1;
    this.selectedLines = [];

    if (mollySpotId == this.selectedMollyIndex) {
      this.getAllLines();
      return;
    }

    this.selectedMollyIndex = mollySpotId;
    this.filteredLines = this.getLinesDependingOnSelectedAgent().filter(
      (line) => line.mollySpotId == mollySpotId
    );
  }

  getLinesDependingOnSelectedAgent() {
    if (this.selectedAgent == Agents.Viper) {
      return this.mapData.viperLines;
    }
    return this.mapData.lines;
  }

  selectLine(line: Line) {
    window.localStorage.setItem('selectedLineupLink', line.imageUrl);
    if (this.isEditingLine) {
      this.selectLineForEdit(line);
      return;
    }

    this.selectedLines = this.filteredLines.filter(
      (x) =>
        x.x1 == line.x1 && x.y1 == line.y1 && x.x2 == line.x2 && x.y2 == line.y2
    );
    if (this.shouldAutoscroll) {
      let elementList = document.querySelectorAll('.right');
      let element = elementList[0] as HTMLElement;
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  }

  getStyleForSelectedLine(line: Line) {
    if (this.selectedLines.includes(line)) {
      return { stroke: 'red' };
    }
    return null;
  }

  startCreateNewPlantSpot() {
    this.isCreatingNewPlantSpot = true;
  }

  startEditingPlantSpot() {
    this.isEditingPlantSpot = true;
  }

  selectPlantSpotForEdit(index: number) {
    this.plantSpotIndexForEdit = index;
    let plantSpot = this.mapData.plantSpots[index];
    this.devX = plantSpot.x / 10;
    this.devY = plantSpot.y / 10;
  }

  startCreateNewMollySpot() {
    this.isCreatingNewMollySpot = true;
  }

  startEditingMollySpot() {
    this.isEditingMollySpot = true;
  }

  selectMollySpotForEdit(index: number) {
    this.mollySpotIndexForEdit = index;
    let mollySpot = this.mapData.mollySpots[index];
    if (this.selectedAgent == Agents.Brimstone) {
      mollySpot = this.mapData.mollySpots[index];
    } else if (this.selectedAgent == Agents.Viper) {
      mollySpot = this.mapData.snakeBiteSpots[index];
    }
    this.devX = mollySpot.x / 10;
    this.devY = mollySpot.y / 10;
    this.imageUrlForSpot = mollySpot.imageUrl;
  }

  startCreateNewLine() {
    this.isCreatingNewLine = true;
  }

  startEditingLine() {
    this.isEditingLine = true;
  }

  selectLineForEdit(line: Line) {
    if (this.selectedAgent == Agents.Brimstone) {
      this.lineIndexForEdit = this.lineIndexForEdit = this.mapData.lines.indexOf(line);
    } else if (this.selectedAgent == Agents.Viper) {
      this.lineIndexForEdit = this.lineIndexForEdit = this.mapData.viperLines.indexOf(line);
    }
    this.lineDescription = line.description;
    this.imageUrlForSpot = line.imageUrl;
    this.lineLandTime = line.landTime;
    this.selectedPlantSpotIndexForLine = line.plantSpotId;
    this.selectedMollySpotIndexForLine = line.mollySpotId;
  }

  isCreating() {
    return (
      this.isCreatingNewMollySpot ||
      this.isCreatingNewPlantSpot ||
      this.isCreatingNewLine
    );
  }

  isEditing() {
    return (
      this.isEditingPlantSpot || this.isEditingMollySpot || this.isEditingLine
    );
  }

  finishEditSave() {
    this.mapDataService.reloadDataForCurrentMap();
    this.resetCreation();
  }

  resetCreation() {
    this.isCreatingNewPlantSpot = false;
    this.isCreatingNewMollySpot = false;
    this.isCreatingNewLine = false;
    this.isEditingPlantSpot = false;
    this.isEditingMollySpot = false;
    this.isEditingLine = false;
    this.selectedPlantSpotIndexForLine = -1;
    this.selectedMollySpotIndexForLine = -1;
  }

  createNewPlantSpot() {
    let newPlantSpot = new PlantSpot(this.devX * 10, this.devY * 10, '');
    this.mapDataService.createNewPlantSpot(newPlantSpot);
    this.finishEditSave();
  }

  savePlantSpot() {
    let newPlantSpot = new PlantSpot(this.devX * 10, this.devY * 10, '');
    this.mapDataService.editPlantSpot(newPlantSpot, this.plantSpotIndexForEdit);
    this.finishEditSave();
  }

  deletePlantSpot() {
    this.mapDataService.deletePlantSpot(this.plantSpotIndexForEdit);
    if (confirm('ARE YOU SURE YOU WANT TO DELETE THIS PLANT SPOT')) {
      this.mapDataService.deletePlantSpot(this.plantSpotIndexForEdit);
    } else {
      return;
    }
    this.finishEditSave();
  }

  createNewMollySpot() {
    let newMollySpot = new MollySpot(
      this.devX * 10,
      this.devY * 10,
      this.imageUrlForSpot
    );
    this.mapDataService.createNewMollySpot(newMollySpot);
    this.finishEditSave();
  }

  saveMollySpot() {
    let newMollySpot = new MollySpot(
      this.devX * 10,
      this.devY * 10,
      this.imageUrlForSpot
    );
    this.mapDataService.editMollySpot(
      newMollySpot,
      this.mollySpotIndexForEdit
    );
    this.finishEditSave();
  }

  deleteMollySpot() {
    if (confirm('ARE YOU SURE YOU WANT TO DELETE THIS MOLLY SPOT')) {
      this.mapDataService.deleteMollySpot(this.mollySpotIndexForEdit);
    } else {
      return;
    }
    this.finishEditSave();
  }

  createNewLine() {
    let mollySpot = this.mapData.mollySpots[this.selectedMollySpotIndexForLine];
    if (this.selectedAgent == Agents.Viper) {
      mollySpot = this.mapData.snakeBiteSpots[this.selectedMollySpotIndexForLine]
    }
    let newLine = new Line(
      mollySpot,
      this.mapData.plantSpots[this.selectedPlantSpotIndexForLine],
      this.selectedMollySpotIndexForLine,
      this.selectedPlantSpotIndexForLine,
      this.imageUrlForSpot,
      this.lineDescription,
      this.lineLandTime
    );
    this.mapDataService.createNewLine(newLine);
    this.finishEditSave();
  }

  saveLine() {
    let mollySpot = this.mapData.mollySpots[this.selectedMollySpotIndexForLine];
    if (this.selectedAgent == Agents.Viper) {
      mollySpot = this.mapData.snakeBiteSpots[this.selectedMollySpotIndexForLine]
    }
    let newLine = new Line(
      mollySpot,
      this.mapData.plantSpots[this.selectedPlantSpotIndexForLine],
      this.selectedMollySpotIndexForLine,
      this.selectedPlantSpotIndexForLine,
      this.imageUrlForSpot,
      this.lineDescription,
      this.lineLandTime
    );
    this.mapDataService.editLine(newLine, this.lineIndexForEdit);
    this.finishEditSave();
  }

  deleteLine() {
    if (confirm('ARE YOU SURE YOU WANT TO DELETE THIS LINE')) {
      this.mapDataService.deleteLine(this.lineIndexForEdit);
    } else {
      return;
    }
    this.finishEditSave();
  }

  getClassForPlantSpot(index: number) {
    return index == this.selectedPlantSpotIndexForLine ? 'selectedForLine' : '';
  }

  getClassForMollySpot(index: number) {
    return index == this.selectedMollySpotIndexForLine ? 'selectedForLine' : '';
  }
}
