import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Agents } from '../entities/Agents';

@Injectable({
  providedIn: 'root'
})
export class OptionsService {
  selectedAgent = new BehaviorSubject<Agents>(Agents.Brimstone);

  constructor() { }
}
