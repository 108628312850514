import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Line } from '../entities/Line';
import { MapData } from '../entities/MapData';
import { MollySpot } from '../entities/MollySpot';
import { PlantSpot } from '../entities/PlantSpot';
import { DatabaseService } from './database.service';
import { SubscriptionOwner } from './subscription-owner.service';
import { OptionsService } from './options.service';
import { Agents } from '../entities/Agents';

@Injectable({
  providedIn: 'root',
})
export class MapDataService extends SubscriptionOwner {
  selectedMapDataValue: MapData = new MapData();
  selectedMapData = new Subject<MapData>();
  selectedAgent = Agents.Brimstone;

  constructor(
    private databaseService: DatabaseService,
    private options: OptionsService
  ) {
    super();
    this.subscriptions.push(
      this.selectedMapData.subscribe((map) => {
        this.selectedMapDataValue = map;
      })
    );
    this.subscriptions.push(this.options.selectedAgent.subscribe(x => {
      this.selectedAgent = x;
    }));
  }


  getDataForMap(mapName: string) {
    switch (mapName) {
      case 'Ascent':
        this.getAscentData();
        break;
      case 'Split':
        this.getSplitData();
        break;
      case 'Bind':
        this.getBindData();
        break;
      case 'Haven':
        this.getHavenData();
        break;
      case 'Fracture':
        this.getFractureData();
        break;
      case 'Icebox':
        this.getIceboxData();
        break;
      case 'Breeze':
        this.getBreezeData();
        break;
      case 'Pearl':
        this.getPearlData();
        break;
      case 'Lotus':
        this.getLotusData();
        break;
      case 'Sunset':
        this.getSunsetData();
        break;
      case 'Abyss':
        this.getAbyssData();
        break;

      default:
        this.getAscentData();
    }
  }

  reloadDataForCurrentMap() {
    this.getDataForMap(this.selectedMapDataValue.mapName);
  }

  createNewMollySpot(newMollySpot: MollySpot) {
    let indexToAdd = -1;
    switch(this.selectedAgent) {
      case Agents.Brimstone:
        indexToAdd = this.selectedMapDataValue.mollySpots.length;
        break;
      case Agents.Viper:
        if (this.selectedMapDataValue.snakeBiteSpots) {
          indexToAdd = this.selectedMapDataValue.snakeBiteSpots.length;
        } else {
          indexToAdd = 0;
        }
        break;
    }

    this.databaseService.createMollySpot(
      this.selectedMapDataValue.mapName,
      newMollySpot,
      indexToAdd
    );
  }

  editMollySpot(newMollySpot: MollySpot, mollySpotIndex: number) {
    this.databaseService.editMollySpot(
      this.selectedMapDataValue.mapName,
      newMollySpot,
      mollySpotIndex
    );
  }

  deleteMollySpot(mollySpotIndex: number) {
    this.databaseService.deleteMollySpot(
      this.selectedMapDataValue.mapName,
      mollySpotIndex
    );
  }

  createNewPlantSpot(newPlantSpot: PlantSpot) {
    this.databaseService.createPlantSpot(
      this.selectedMapDataValue.mapName,
      newPlantSpot,
      this.selectedMapDataValue.plantSpots.length
    );
  }

  editPlantSpot(newPlantSpot: PlantSpot, plantSpotIndex: number) {
    this.databaseService.editPlantSpot(
      this.selectedMapDataValue.mapName,
      newPlantSpot,
      plantSpotIndex
    );
  }

  deletePlantSpot(plantSpotIndex: number) {
    this.databaseService.deletePlantSpot(
      this.selectedMapDataValue.mapName,
      plantSpotIndex
    );
  }

  createNewLine(newLine: Line) {
    let indexToAdd = -1;
    switch(this.selectedAgent) {
      case Agents.Brimstone:
        indexToAdd = this.selectedMapDataValue.lines.length;
        break;
      case Agents.Viper:
        if (this.selectedMapDataValue.viperLines) {
          indexToAdd = this.selectedMapDataValue.viperLines.length;
        } else {
          indexToAdd = 0;
        }
        break;
    }
    this.databaseService.createLine(
      this.selectedMapDataValue.mapName,
      newLine,
      indexToAdd
    );
  }

  editLine(newLine: Line, lineIndex: number) {
    this.databaseService.editLine(
      this.selectedMapDataValue.mapName,
      newLine,
      lineIndex
    );
  }

  deleteLine(lineIndex: number) {
    this.databaseService.deleteLine(
      this.selectedMapDataValue.mapName,
      lineIndex
    );
  }

  getAscentData() {
    this.databaseService.getDataForMap('Ascent').subscribe((x) => {
      if (x.length > 0) {
        this.selectedMapData.next(x[0]);
      }
    });
  }

  getSplitData() {
    this.databaseService.getDataForMap('Split').subscribe((x) => {
      if (x.length > 0) {
        this.selectedMapData.next(x[0]);
      }
    });
  }

  getBindData() {
    this.databaseService.getDataForMap('Bind').subscribe((x) => {
      if (x.length > 0) {
        this.selectedMapData.next(x[0]);
      }
    });
  }

  getHavenData() {
    this.databaseService.getDataForMap('Haven').subscribe((x) => {
      if (x.length > 0) {
        this.selectedMapData.next(x[0]);
      }
    });
  }

  getFractureData() {
    this.databaseService.getDataForMap('Fracture').subscribe((x) => {
      if (x.length > 0) {
        this.selectedMapData.next(x[0]);
      }
    });
  }

  getIceboxData() {
    this.databaseService.getDataForMap('Icebox').subscribe((x) => {
      if (x.length > 0) {
        this.selectedMapData.next(x[0]);
      }
    });
  }

  getBreezeData() {
    this.databaseService.getDataForMap('Breeze').subscribe((x) => {
      if (x.length > 0) {
        this.selectedMapData.next(x[0]);
      }
    });
  }

  getPearlData() {
    this.databaseService.getDataForMap('Pearl').subscribe((x) => {
      if (x.length > 0) {
        this.selectedMapData.next(x[0]);
      }
    });
  }

  getLotusData() {
    this.databaseService.getDataForMap('Lotus').subscribe((x) => {
      if (x.length > 0) {
        this.selectedMapData.next(x[0]);
      }
    })
  }

  getSunsetData() {
    this.databaseService.getDataForMap('Sunset').subscribe((x) => {
      if (x.length > 0) {
        this.selectedMapData.next(x[0]);
      }
    })
  }

  getAbyssData() {
    this.databaseService.getDataForMap('Abyss').subscribe((x) => {
      if (x.length > 0) {
        this.selectedMapData.next(x[0])
      }
    })
  }
}
