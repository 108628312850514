<div>
  <p>
    Hello! I'm zehb, a Brimstone one trick. I made this website as an
    easy reference to almost all the lineups I have found or made. Hopefully you
    find it useful!
  </p>

  <p>
    A lot of the original lineups and inspiration came from
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.twitch.tv/smacksmackk"
    >
      SmackSmackk
    </a>
    (find his awesome doc
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://docs.google.com/document/d/1oK1c7we0JnHmIVGnshVx7CzeFTkBiFN50uY2zP6dILk/edit?usp=sharing"
      >HERE</a
    >), so big shoutout to him and his community.
  </p>
  <p>
    When I'm not playing Valorant, I'm either working (as a Software Engineer)
    or running around in the mountains. Also, I do love a good cup of coffee,
    feel free to
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.buymeacoffee.com/zehb"
      >buy one for me <3</a
    >
  </p>
</div>
