
export class PlantSpot {
  constructor(x: number, y: number, name: string) {
    this.x = x;
    this.y = y;
    this.name = name;
  }
  x: number;
  y: number;
  name: string;
}
