import { Component, OnDestroy, OnInit } from '@angular/core';
import { OptionsService } from '../services/options.service';
import { SubscriptionOwner } from '../services/subscription-owner.service';

@Component({
  selector: 'app-fullscreen-lineup',
  template: `
  <img style="display: block; width: 100%; height: 100%; object-fit: contain;" [src]="link">
  `
})
export class FullscreenLineupComponent implements OnInit, OnDestroy {
  link: string = "";

  funcListener: any;
  func(event: any): void {
    console.log(event)
    if (event.key == 'selectedLineupLink'){
      this.link = event.newValue;
    }
  }

  constructor(
    private options: OptionsService
  ) {
    this.funcListener = this.func.bind(this);
    window.addEventListener('storage', this.funcListener);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      window.removeEventListener('storage', this.funcListener);
  }
}
