import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MapDataService } from '../services/map-data.service';
import { OptionsService } from '../services/options.service';

const MollyLineupsHasVisited = "MollyLineupsHasVisited";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  maps = ['Ascent', 'Split', 'Bind', 'Haven', 'Fracture', 'Icebox', 'Breeze', 'Pearl', 'Lotus', 'Sunset', 'Abyss'];

  favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');
  showFirstTime: boolean = false;

  constructor(
    private mapDataService: MapDataService,
    private options: OptionsService,
    private titleService: Title
    ) { }

  ngOnInit(): void {
    if (window.localStorage.getItem(MollyLineupsHasVisited) == null) {
      this.showFirstTime = true;
      window.localStorage.setItem(MollyLineupsHasVisited, "true");
    }

    this.mapDataService.getAbyssData();
  }

  selectMap(mapIndex: number) {
    this.mapDataService.getDataForMap(this.maps[mapIndex]);
  }

  selectAgent(agentIndex: number) {
    this.options.selectedAgent.next(agentIndex);
    switch (agentIndex) {
      case 0:
        this.titleService.setTitle("BrimLineups");
        if (this.favIcon) {
          this.favIcon.href = "../assets/Images/brimstone.ico";
        }
        break;
      case 1:
        this.titleService.setTitle("*ViperLineups")
        if (this.favIcon) {
          this.favIcon.href = "../assets/Images/viper.ico";
        }
        break;
    }
  }

}
