import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { LineUpsComponent } from './lineups/lineups.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MollyComponent } from './molly/molly.component';
import { PlantSpotComponent } from './plant-spot/plant-spot.component';
import { FormsModule } from '@angular/forms';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/compat/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';
import { LoginComponentComponent } from './login-component/login-component.component';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { AboutComponent } from './about/about.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { ChangelogComponent } from './changelog/changelog.component';
import { SettingsComponent } from './settings/settings.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullscreenLineupComponent } from './fullscreen-lineup/fullscreen-lineup.component';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'tutorial', component: TutorialComponent},
  {path: 'changelog', component: ChangelogComponent},
  {path: 'settings', component: SettingsComponent},
  {path: 'fullscreen', component: FullscreenLineupComponent}
]

@NgModule({
  declarations: [
    AppComponent,
    LineUpsComponent,
    MollyComponent,
    PlantSpotComponent,
    LoginComponentComponent,
    HomeComponent,
    MenuComponent,
    AboutComponent,
    TutorialComponent,
    ChangelogComponent,
    SettingsComponent,
    FullscreenLineupComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    FontAwesomeModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    FlexLayoutModule
  ],
  providers: [
    ScreenTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
