import { MollySpot } from "./MollySpot";
import { PlantSpot } from "./PlantSpot";


export class Line {
  constructor(
    mollySpot: MollySpot,
    plantSpot: PlantSpot,
    mollySpotId: number,
    plantSpotId: number,
    imageUrl: string,
    description: string,
    landTime: number
  ) {
    this.imageUrl = imageUrl;
    this.description = description;
    this.landTime = landTime;
    this.mollySpotId = mollySpotId;
    this.plantSpotId = plantSpotId;
    this.x1 = (mollySpot.x + 5) / 10 + '%';
    this.y1 = (mollySpot.y + 10) / 10 + '%';
    this.x2 = (plantSpot.x + 7) / 10 + '%';
    this.y2 = (plantSpot.y + 10) / 10 + '%';
  }

  imageUrl: string;
  description: string;
  landTime: number;
  mollySpotId: number;
  plantSpotId: number;
  x1: string;
  y1: string;
  x2: string;
  y2: string;
}
