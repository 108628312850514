import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faFireFlameCurved } from '@fortawesome/free-solid-svg-icons';
import { Agents } from '../entities/Agents';
import { MollySpot } from "../entities/MollySpot";
import { OptionsService } from '../services/options.service';
import { SubscriptionOwner } from '../services/subscription-owner.service';

@Component({
  selector: 'molly',
  template: `
    <fa-icon
      class="molly"
      [ngStyle]="mollyStyle"
      [icon]="faFireFlameCurved"
      (mouseover)="isHovering = true"
      (mouseout)="isHovering = false"
      (click)="onClick()"
    ></fa-icon>
    <fa-icon
      *ngIf="isHovering"
      class="molly-outline"
      [ngStyle]="mollyStyle"
      [icon]="faCircle"
      transform="grow-8 left-2"
    ></fa-icon>
    <img
      *ngIf="isHovering"
      [ngStyle]="imageStyle"
      class="molly-hover"
      [src]="mollySpot.imageUrl"
    />
  `,
})
export class MollyComponent extends SubscriptionOwner implements OnInit {
  faFireFlameCurved = faFireFlameCurved;
  faCircle = faCircle;

  mollyStyle: any;
  imageStyle: any;
  isHovering: boolean = false;

  @Input() mollySpot: MollySpot = new MollySpot(0, 0, "");

  @Output() mollyClicked = new EventEmitter();
  selectedAgent = Agents.Brimstone;

  constructor(private options: OptionsService) {
    super();
    this.subscriptions.push(this.options.selectedAgent.subscribe(x => {
      this.selectedAgent = x;
    }))
  }

  ngOnInit() {
    let color = "red";
    if (this.selectedAgent == Agents.Viper) {
      color = "#40f100"
    }
    this.mollyStyle = {
      left: this.mollySpot.x/10 + '%',
      top: this.mollySpot.y/10 + '%',
      color: color
    };
    let imageStyleAbove = {
      left: this.mollySpot.x/10 + '%',
      bottom: 100 - this.mollySpot.y/10 + '%'
    }
    let imageStyleBelow = {
      left: this.mollySpot.x/10 + '%',
      top: this.mollySpot.y/10 + '%'
    }
    if (this.mollySpot.y/10 < 30) {
      this.imageStyle = imageStyleBelow;
    } else {
      this.imageStyle = imageStyleAbove;
    }
  }

  onClick() {
    this.mollyClicked.emit();
  }
}
