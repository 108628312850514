import { Component, OnInit } from '@angular/core';
import { faBomb, faFireFlameCurved } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html'
})
export class TutorialComponent implements OnInit {

  faFireFlameCurved = faFireFlameCurved;
  faBomb = faBomb;

  constructor() { }

  ngOnInit(): void {
  }

}
