import { Line } from "./Line";
import { MollySpot } from "./MollySpot";
import { PlantSpot } from "./PlantSpot";

export class MapData {
  mapName: string = "";
  plantSpots: PlantSpot[] = [];
  mollySpots: MollySpot[] = [];
  snakeBiteSpots: MollySpot[] = [];
  lines: Line[] = [];
  viperLines: Line[] = [];
  imageUrl: string = "";
}
