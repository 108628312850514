export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDD38qJT3knrHEXjBl6oUk3yHeUwKJHILk",
    authDomain: "brim-lineups.firebaseapp.com",
    databaseURL: "https://brim-lineups-default-rtdb.firebaseio.com",
    projectId: "brim-lineups",
    storageBucket: "brim-lineups.appspot.com",
    messagingSenderId: "686494095104",
    appId: "1:686494095104:web:59d802fa953c8d558845e5",
    measurementId: "G-TNB2T2SJ5E"
  },
  allowedEmails: ["zmillslagle@gmail.com"]
};
