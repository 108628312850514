
  <mat-toolbar>
    <mat-toolbar-row>
      <div fxShow="true" fxHide.gt-sm="true">
        <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
      </div>
      <h1 [routerLink]="'/home'">Molly Lineups</h1>
      <span class="menu-spacer"></span>
      <div class="menu-buttons-outer">
        <div class="menu-buttons-inner1" fxShow="true" fxHide.lt-md="true">
          <h3><a [routerLink]="'/home'">Home</a></h3>
          <h3><a [routerLink]="'/tutorial'">Tutorial</a></h3>
          <h3><a [routerLink]="'/changelog'">Changelog</a></h3>
          <h3><a [routerLink]="'/about'">About</a></h3>
        </div>
        <div class="menu-buttons-inner2" fxShow="true" fxHide.lt-md="true">
          <!-- <mat-icon [routerLink]="'/settings'">settings</mat-icon> -->
          <app-login-component style="align-self: center"></app-login-component>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container fxFlexFill>
    <mat-sidenav
      #sidenav
      fxLayout="column"
      mode="over"
      opened="false"
      fxHide.gt-sm="true"
    >
      <div fxLayout="column">
        <h3><a (click)="sidenav.toggle()" [routerLink]="'/home'">Home</a></h3>
        <h3><a (click)="sidenav.toggle()" [routerLink]="'/tutorial'">Tutorial</a></h3>
        <h3><a (click)="sidenav.toggle()" [routerLink]="'/changelog'">Changelog</a></h3>
        <h3><a (click)="sidenav.toggle()" [routerLink]="'/about'">About</a></h3>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="spacer"></div>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>

