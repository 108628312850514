
export class MollySpot {
  constructor(x: number, y: number, imageUrl: string) {
    this.x = x;
    this.y = y;
    this.imageUrl = imageUrl;
  }
  x: number;
  y: number;
  imageUrl: string;
}
