import { Component, OnInit } from '@angular/core';
import { faBomb, faFireFlameCurved } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  faFireFlameCurved = faFireFlameCurved;
  faBomb = faBomb;

  constructor() {}
}
