<div style="width: 100%">
  <div style="padding: 10px">
    <label class="switch">
      <input type="checkbox" [(ngModel)]="shouldAutoscroll" />
      <span class="slider" style="color: white"
        >Auto scroll to lineup picture</span
      >
    </label>
  </div>
  <ng-container *ngIf="isAdmin">
    <div *ngIf="!isCreating() && !isEditing()">
      <button  (click)="startCreateNewPlantSpot()">New Plant Spot</button>
      <button  (click)="startEditingPlantSpot()">Edit Plant Spot</button>
    </div>
    <div *ngIf="!isCreating() && !isEditing()">
      <button  (click)="startCreateNewMollySpot()">New Molly Spot</button>
      <button  (click)="startEditingMollySpot()">Edit Molly Spot</button>
    </div>
    <div *ngIf="!isCreating() && !isEditing()">
      <button (click)="startCreateNewLine()">New Line</button>
      <button (click)="startEditingLine()">Edit Line</button>
    </div>
    <div *ngIf="isCreating() || isEditing()">
      <button (click)="resetCreation()">Stop</button>
    </div>

    <ng-container *ngIf="isEditing()">
        <p style="color: white;">Click an icon below to edit</p>
        <p style="color: white;">WHEN EDITING LOCATION OF PLANT/MOLLY SPOT, YOU WILL ALSO NEED TO UPDATE LINE LATER (I'M LAZY AND DIDN'T IMPLEMENT THIS YET)</p>
    </ng-container>

    <ng-container *ngIf="isCreatingNewPlantSpot || isCreatingNewMollySpot || isEditingPlantSpot || isEditingMollySpot">
      <div style="width: 1000px;">
        <input type="range" min="1" max="100" [(ngModel)]="devX" class="slider" />
        <input type="number" min="1" max="100" [(ngModel)]="devX"/>
        <input type="range" min="1" max="100" [(ngModel)]="devY" class="slider" />
        <input type="number" min="1" max="100" [(ngModel)]="devY"/>
      </div>
    </ng-container>
    <ng-container *ngIf="isCreatingNewLine || isEditingLine">
      <div>
        <button (click)="isSelectingMollySpot = !isSelectingMollySpot">{{isSelectingMollySpot ? "Stop selecting" : "Select "}} Molly Spot</button>
        <p *ngIf="isSelectingMollySpot" style="color: white;">Click a Molly icon below</p>
      </div>
      <div>
        <button (click)="isSelectingPlantSpot = !isSelectingPlantSpot">{{isSelectingPlantSpot ? "Stop selecting " : "Select "}} Plant Spot</button>
        <p *ngIf="isSelectingPlantSpot" style="color: white;"> Click a Plant icon below</p>
      </div>
      <div>
        <label style="color: white;">Description: </label>
        <input [(ngModel)]="lineDescription"/>
      </div>
      <div>
        <label style="color: white;">Land Time: </label>
        <input [(ngModel)]="lineLandTime"/>
      </div>
    </ng-container>
    <div *ngIf="isCreatingNewMollySpot || isCreatingNewLine || isEditingMollySpot || isEditingLine">
      <label style="color: white;">Imgur URL for spot: </label>
      <input [(ngModel)]="imageUrlForSpot"/>
    </div>

    <div>
      <button *ngIf="isCreatingNewPlantSpot" (click)="createNewPlantSpot()">Create New Plant Spot</button>
      <button *ngIf="isEditingPlantSpot" (click)="savePlantSpot()">Save Plant Spot</button>
      <button *ngIf="false" (click)="deletePlantSpot()" style="float: right; background: red;">Delete Plant Spot</button>
    </div>
    <div>
      <button *ngIf="isCreatingNewMollySpot" (click)="createNewMollySpot()">Create New Molly Spot</button>
      <button *ngIf="isEditingMollySpot" (click)="saveMollySpot()">Save Molly Spot</button>
      <button *ngIf="false" (click)="deleteMollySpot()" style="float: right; background: red;">Delete Molly Spot</button>
    </div>
    <div>
      <button *ngIf="isCreatingNewLine" (click)="createNewLine()">Create New Line</button>
      <button *ngIf="isEditingLine" (click)="saveLine()">Save Line</button>
      <button *ngIf="false" (click)="deleteLine()" style="float: right; background: red;">Delete Line</button>
    </div>
  </ng-container>

  <div class="container left">
    <molly
      *ngFor="let mollySpot of mollySpots; let i = index"
      [mollySpot]="mollySpot"
      (mollyClicked)="getLinesByMollySpot(i)"
      [class]="getClassForMollySpot(i)"
    ></molly>
    <plant-spot
      *ngFor="let plant of mapData.plantSpots; let i = index"
      [x]="plant.x"
      [y]="plant.y"
      [name]="plant.name"
      (plantClicked)="getLinesByPlantSpot(i)"
      [class]="getClassForPlantSpot(i)"
    ></plant-spot>
    <svg class="lines-container">
      <line
        class="line"
        *ngFor="let line of filteredLines"
        [attr.x1]="line.x1"
        [attr.y1]="line.y1"
        [attr.x2]="line.x2"
        [attr.y2]="line.y2"
        stroke="black"
        [ngStyle]="getStyleForSelectedLine(line)"
        (click)="selectLine(line)"
      ></line>
      <line
        class="line"
        style="stroke-width: 7"
        *ngFor="let line of filteredLines"
        [attr.x1]="line.x1"
        [attr.y1]="line.y1"
        [attr.x2]="line.x2"
        [attr.y2]="line.y2"
        stroke="transparent"
        (click)="selectLine(line)"
      ></line>
    </svg>
    <img width="1000" [src]="mapData.imageUrl" />

    <ng-container *ngIf="isCreatingNewPlantSpot || isCreatingNewMollySpot || isEditingMollySpot || isEditingPlantSpot">
      <div>
        <fa-icon
          [ngStyle]="{ left: devX + '%', top: devY + '%' }"
          class="dev-circle"
          [icon]="faCircle"
          transform="shrink-5"
        ></fa-icon>
      </div>
    </ng-container>

  </div>
  <div class="right">
    <ng-container *ngFor="let line of selectedLines">
      <h1 *ngIf="line.landTime" class="land-time" style="color: red">
        {{ line.landTime }}s
      </h1>
      <img width="100%" [src]="line.imageUrl" />
      <p style="color: white">
        {{ line.description }}
      </p>
    </ng-container>
  </div>


</div>
