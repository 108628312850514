<h1 style="color: white; text-align: center;">How to use this site:</h1>
<div style="justify-content: center;">
  <img style="display: block; margin: auto;" src="https://i.imgur.com/7ZClczO.gif" title="source: imgur.com" />
</div>
<div style="text-align: center; color: white">
  (Consider zooming out so both the picture and the map fit on your screen)
  <br/>
  <fa-icon [icon]="faBomb" style="color: blue"></fa-icon> = Plant spot, where
  you would plant the bomb. Click to filter. Click again to show all.
  <br />
  <fa-icon [icon]="faFireFlameCurved" style="color: red"></fa-icon> = Molly spot, where you
  throw the molly from. Hover to show where you stand. Click to filter. Click
  again to show all.
  <br />
  <span style="color: black; font-size: 20px; font-weight: bold;">--------</span>&nbsp; = A lineup between a molly spot and a plant spot. Click to see where to
  lineup (look below picture for a description)
  <br />
  <b style="color: red">5.89s</b> = When provided, upper right of lineup picture
  will show how long molly takes to pop after being fired.
</div>
