import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { SubscriptionOwner } from '../services/subscription-owner.service';

@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html'
})
export class LoginComponentComponent
  extends SubscriptionOwner
  implements OnInit
{
  isAdmin: boolean = false;
  isLoggedIn: boolean = false;

  constructor(public authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.authService.isAdmin.subscribe((x) => {
        this.isAdmin = x;
      })
    );
    this.subscriptions.push(
      this.authService.isLoggedIn.subscribe((x) => {
        this.isLoggedIn = x;
      })
    );
  }
}
