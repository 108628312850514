import { Component, OnInit } from '@angular/core';
import { Change } from '../entities/Change';
import { DatabaseService } from '../services/database.service';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.css']
})
export class ChangelogComponent implements OnInit {
  changes: Change[] = [];

  constructor(
    private databaseService: DatabaseService
  ) { }

  ngOnInit(): void {
    this.databaseService.getChangelog().subscribe(changes => {
      this.changes = changes;
    })
  }

}
