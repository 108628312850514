import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { map } from 'rxjs/operators'
import { Agents } from "../entities/Agents";
import { Change } from "../entities/Change";
import { Line } from "../entities/Line";
import { MapData } from "../entities/MapData";
import { MollySpot } from "../entities/MollySpot";
import { PlantSpot } from "../entities/PlantSpot";
import { OptionsService } from "./options.service";
import { SubscriptionOwner } from "./subscription-owner.service";

@Injectable({
  providedIn: 'root',
})
export class DatabaseService extends SubscriptionOwner {
  mollyListName = "molly";
  lineListName = "lines";


  constructor(
    private db: AngularFireDatabase,
    private options: OptionsService
  ) {
    super();
    this.subscriptions.push(this.options.selectedAgent.subscribe(agent => {
      switch (agent) {
        case Agents.Brimstone:
          this.mollyListName = "mollySpots";
          this.lineListName = "lines";
          break;
        case Agents.Viper:
          this.mollyListName = "snakeBiteSpots"
          this.lineListName = "viperLines"
      }
    }))
  }

  getDataForMap(mapName: string) {
    return this.db.list('/mapData', ref => ref.orderByKey().equalTo(mapName)).snapshotChanges().pipe(
      map((items: any[]) => items.map(item => {
        let payload = item.payload.val();
        let key = item.key;
        return <MapData>{key, ...payload};
      }))
    );
  }

  createMollySpot(mapName: string, mollySpot: MollySpot, mollySpotIndex: number) {
    let mollyListRef = this.db.database.ref(`/mapData/${mapName}/${this.mollyListName}`);
    mollyListRef.child(mollySpotIndex.toString()).set(mollySpot);
  }

  editMollySpot(mapName: string, mollySpot: MollySpot, mollySpotIndex: number) {
    let mollySpotRef = this.db.database.ref(`/mapData/${mapName}/${this.mollyListName}/${mollySpotIndex}`);
    mollySpotRef.set(mollySpot);
  }

  deleteMollySpot(mapName: string, mollySpotIndex: number) {
    let mollySpotRef = this.db.database.ref(`/mapData/${mapName}/${this.mollyListName}/${mollySpotIndex}`);
    mollySpotRef.remove();
  }

  createPlantSpot(mapName: string, plantSpot: PlantSpot, plantSpotIndex: number) {
    let plantListRef = this.db.database.ref('/mapData/' + mapName + '/plantSpots');
    plantListRef.child(plantSpotIndex.toString()).set(plantSpot);
  }

  editPlantSpot(mapName: string, plantSpot: PlantSpot, plantSpotIndex: number) {
    let plantSpotRef = this.db.database.ref('/mapData/' + mapName + '/plantSpots/' + plantSpotIndex);
    plantSpotRef.set(plantSpot);
  }

  deletePlantSpot(mapName: string, plantSpotIndex: number) {
    let plantSpotRef = this.db.database.ref('/mapData/' + mapName + '/plantSpots/' + plantSpotIndex);
    plantSpotRef.remove();
  }

  createLine(mapName: string, line: Line, lineIndex: number) {
    let lineListRef = this.db.database.ref(`/mapData/${mapName}/${this.lineListName}`);
    lineListRef.child(lineIndex.toString()).set(line);
  }

  editLine(mapName: string, line: Line, lineIndex: number) {
    let lineRef = this.db.database.ref(`/mapData/${mapName}/${this.lineListName}/${lineIndex}`);
    lineRef.set(line);
  }

  deleteLine(mapName: string, lineIndex: number) {
    let lineRef = this.db.database.ref(`/mapData/${mapName}/${this.lineListName}/${lineIndex}`);
    lineRef.remove();
  }

  getChangelog() {
    return this.db.list('/changelog').snapshotChanges().pipe(
      map((items: any[]) => items.map(item => {
        let payload = item.payload.val();
        let key = item.key;
        return <Change>{key, ...payload};
      }).sort(function(a,b) {
        let adate = a.date.split('.')[2] + a.date.split('.')[0] + a.date.split('.')[1]
        let bdate = b.date.split('.')[2] + b.date.split('.')[0] + b.date.split('.')[1]
        console.log(adate, bdate)
        return adate > bdate ? -1 : adate < bdate ? 1 : 0;
      }))
    );
  }
}
