<div *ngIf="showFirstTime">
  <app-tutorial></app-tutorial>
</div>

<div>
  <div class="btn-container">
    <button class="btn btn--light" (click)="selectAgent(0)">
      <span class="btn__inner">
        <span class="btn__slide"></span>
        <span class="btn__content">Brimstone</span>
      </span>
    </button>
  </div>
  <div class="btn-container">
    <button class="btn btn--light" (click)="selectAgent(1)">
      <span class="btn__inner">
        <span class="btn__slide"></span>
        <span class="btn__content">Viper</span>
      </span>
    </button>
  </div>
</div>
<div class="btn-container" *ngFor="let map of maps; let i = index">
  <button class="btn" (click)="selectMap(i)">
    <span class="btn__inner">
      <span class="btn__slide"></span>
      <span class="btn__content">{{ map }}</span>
    </span>
  </button>
</div>

<lineups></lineups>
