import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionOwner implements OnDestroy {
	constructor() {}
	ngOnDestroy(): void {
		this.subscriptions.forEach(x => {
			x.unsubscribe();
		});
		this.subscriptions = [];
	}
	subscriptions: Subscription[] = [];
}
